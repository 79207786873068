import React from "react";
import Layout from "../utils/layout";

export default () => {
  return (
    <Layout>
      <div style={{ margin: "20px auto", maxWidth: 600 }} itemprop="text">
        <p class="p1">
          <span class="s1">Last updated: August 21</span>
          <span class="s1">, 2020</span>
        </p>
        <p class="p3">
          <span class="s1">
            Share Bibles (“<b>Share Bibles</b>,” “<b>Company</b>,” “<b>we</b>,”
            or “<b>us</b>”) respects the privacy rights of our online visitors
            and mobile application users. This Privacy Policy applies to
            information that we collect about you through our websites, mobile
            sites, mobile applications, and other interactive features or
            downloads (collectively the “Sites”) which, in each case, link to or
            incorporate this Privacy Policy.
            <span class="Apple-converted-space">&nbsp; </span>We will not use or
            share your information with anyone except as described in this
            Privacy Policy.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            This Privacy Policy explains how Share Bibles collects, uses, shares
            and protects (collectively, “<b>process</b>”) information about you
            as an individual user of our online services or mobile applications
            (the “<b>Company Service</b>”). This Privacy Policy also describes
            how you can access/change your information and make choices about
            how your information is used.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            <b>
              PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING
              THE COMPANY SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ,
              UNDERSTAND, AND AGREE TO BE BOUND BY ALL THE TERMS OF THIS PRIVACY
              POLICY AND OUR TERMS OF SERVICE. IF YOU DO NOT AGREE TO SUCH
              TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE COMPANY
              SERVICE.
            </b>
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            <b>1. </b>
          </span>
          <span class="s3">
            <b>INTRODUCTION</b>
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            1.1 The Company is committed to safeguarding the privacy of our
            website and Users (“<b>Users</b>” or “<b>you</b>”).
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            1.2 This policy applies where we are acting as a data processor with
            respect to your personal data; in other words, where in
            collaboration with others determine what needs to happen with the
            personal data and the collection of personal data.
            <span class="Apple-converted-space">&nbsp; </span>The other party
            taking part in this determination is referred to as a data
            controller, which determines what data needs to be collected.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            1.3 This Privacy Policy does not apply to (i) data collected by
            other entities or organizations or (ii) data collected by third
            party website, or content or applications of third parties, that may
            be linked to or from or accessible from our Services.
            <span class="Apple-converted-space">&nbsp; </span>Such links are
            provided only as a convenience. If you use these links to such
            websites, you may leave the Services. Company does not control nor
            endorse any such third-party websites and we you should read the
            privacy policies of any such website as we are not responsible for
            such policies.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            1.4 Terms of Service. Use of the Company Service is subject to the
            terms of our Terms of Service which is hereby incorporated and made
            part of this Privacy Policy. By using the Company Service, you agree
            to be bound by our Terms of Service.{" "}
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            1.5 We continually strive to find new ways to enhance your
            experience with the Company Service and we may modify this Privacy
            Policy from time to time to reflect changes in our privacy
            practices.<span class="Apple-converted-space">&nbsp; </span>You are
            encouraged to review this Privacy Policy periodically and to check
            the “Last Updated” date at the top of the Privacy Policy for the
            most recent version.
            <span class="Apple-converted-space">&nbsp; </span>If we make changes
            to this Privacy Policy, we will notify you here, by email, or by
            means of notice through the Website, the Application, or any other
            part of the Company Service.
            <span class="Apple-converted-space">&nbsp; </span>Any changes will
            be effective immediately upon the posting of the revised Privacy
            Policy
          </span>
        </p>
        <p class="p5">
          <span class="s1">2. </span>
          <span class="s3">
            <b>HOW WE USE YOUR PERSONAL DATA.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">2.1 In this Section 2 we have set out:</span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            a. the general categories of personal data that we may process;
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            b. the purposes for which we may process personal data; and
          </span>
        </p>
        <p class="p5">
          <span class="s1"> c. the legal bases of the processing.</span>
        </p>
        <p class="p7">
          <span class="s1">
            2.2 Types and Uses of Collected Information. Company collects two
            types of information about you, “Personally Identifiable
            Information” and “Non-Personally Identifiable Information.”
          </span>
        </p>
        <p class="p3">
          <span class="s1">2.3 </span>
          <span class="s3">Personally Identifiable Information</span>
          <span class="s1">
            .<span class="Apple-converted-space">&nbsp; </span>Personally
            Identifiable Information
          </span>{" "}
          <span class="s1">
            is information that identifies a specific person. When you engage in
            certain activities via the Company Service, including but not
            limited to creating an account, sending feedback, or otherwise
            participating in the Company Service (collectively, “Identification
            Activities”), we may ask you to provide certain information about
            yourself.<span class="Apple-converted-space">&nbsp; </span>If you
            elect to engage in an Identification Activity, we may ask you to
            provide us with certain personal information about yourself, such as
            your name, address, email address, telephone number and/or any other
            information you provide to us, to create your account of profile,
            send communications about them to you, and populate forms for future
            transactions.<span class="Apple-converted-space">&nbsp; </span>In
            the event you choose to not provide certain Personally Identifiable
            information, you may not be able to participate in all of the
            features of the Service.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            a.<span class="Apple-converted-space">&nbsp; </span>if you enroll in
            the Company Service through a third party (such as Facebook or
            Google) then Company may receive Personally Identifiable Information
            from such third party and by using the Company Service, you consent
            to such receipt of Personally Identifiable Information and its use
            pursuant to this Privacy Policy by Company.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            b.<span class="Apple-converted-space">&nbsp; </span>we may use or
            share Personally Identifiable Information to, including but not
            limited to, provide products and/or services to you, to enable
            Third-Party Providers to provide products and/or services to you,
            enhance the operation of the Company Service, improve our marketing
            and promotional efforts, analyze use of the Company Service, and
            tailor your experience with third parties as provided below in this
            Privacy Policy. We may also use Personally Identifiable Information
            to troubleshoot, resolve disputes, accomplish administrative tasks,
            contact you, enforce our agreements with you, including our Terms of
            Service and this Privacy Policy, comply with applicable law, and
            cooperate with law enforcement activities.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            c.<span class="Apple-converted-space">&nbsp; </span>we may process
            data about your use of our website and services (“usage <b>data</b>
            “). The usage data may include your IP address, geographical
            location, browser type and version, operating system, referral
            source, length of visit, page views and website navigation paths, as
            well as information about the timing, frequency and pattern of your
            service use. The source of the usage data is our analytics tracking
            system. This usage data may be processed for the purposes of
            analyzing the use of the website and services. The legal basis for
            this processing is your consent or our legitimate interests, namely
            monitoring and improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            d.<span class="Apple-converted-space">&nbsp; </span>we may process
            your account data (“<b>account data</b>“).
            <span class="Apple-converted-space">&nbsp; </span>The account data
            may include your name and email address. The source of the account
            data is you. The account data may be processed for the purposes of
            operating our website, providing our services, ensuring the security
            of our website and services, maintaining back-ups of our databases
            and communicating with you. The legal basis for this processing is
            your consent or our legitimate interests, namely monitoring and
            improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            e.<span class="Apple-converted-space">&nbsp; </span>we may process
            your information included in your personal profile on our website (“
            <b>profile data</b>“).
            <span class="Apple-converted-space">&nbsp; </span>The profile data
            may include your name, address, telephone number, email address,
            profile pictures, and company name.
            <span class="Apple-converted-space">&nbsp; </span>The profile data
            may be processed for the purposes of enabling and monitoring your
            use of our website and services. The legal basis for this processing
            is your consent or our legitimate interests, namely monitoring and
            improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            f.<span class="Apple-converted-space">&nbsp; </span>we may process
            information that you post for publication on our website or through
            our services (“<b>publication data</b>“). The publication data may
            be processed for the purposes of enabling such publication and
            administering our website and services. The legal basis for this
            processing is your consent or our legitimate interests, namely
            monitoring and improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            g.<span class="Apple-converted-space">&nbsp; </span>we may process
            information contained in any inquiry you submit to us regarding
            goods and/or services (“<b>inquiry data</b>“). The enquiry data may
            be processed for the purposes of offering, marketing and selling
            relevant goods and/or services to you. The legal basis for this
            processing is your consent or our legitimate interests, namely
            monitoring and improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            h.<span class="Apple-converted-space">&nbsp; </span>we may process
            information relating to transactions, including purchases of goods
            and services, that you enter into with us and/or through our website
            (“<b>transaction data</b>“). The transaction data may include first
            and last names, an email address to be used as a login, billing
            contact information, credit card number and a password. The
            transaction data may be processed for the purpose of supplying the
            purchased goods and services and keeping proper records of those
            transactions. The legal basis for this processing is the performance
            of a contract between you and us and/or taking steps, at your
            request, to enter into such a contract and our legitimate interests.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            i.<span class="Apple-converted-space">&nbsp; </span>we may process
            information that you provide to us for the purpose of subscribing to
            our email notifications and/or newsletters (“
            <b>notification data</b>“). The notification data may be processed
            for the purposes of sending you the relevant notifications and/or
            newsletters. The legal basis for this processing is your consent or
            our legitimate interests, namely monitoring and improving our
            website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            j.<span class="Apple-converted-space">&nbsp; </span>we may process
            information contained in or relating to any communication that you
            send to us (“<b>correspondence data</b>“). The correspondence data
            may include the communication content and metadata associated with
            the communication.<span class="Apple-converted-space">&nbsp; </span>
            Our website will generate the metadata associated with
            communications made using the website contact forms. The
            correspondence data may be processed for the purposes of
            communicating with you and record-keeping. The legal basis for this
            processing is your consent or our legitimate interests, namely
            monitoring and improving our website and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            k.<span class="Apple-converted-space">&nbsp; </span>we may process
            any of your personal data identified in this policy where necessary
            for the establishment, exercise or defense of legal claims, whether
            in court proceedings or in an administrative or out-of-court
            procedure. The legal basis for this processing is our legitimate
            interests, namely the protection and assertion of our legal rights,
            your legal rights and the legal rights of others.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            l.<span class="Apple-converted-space">&nbsp; </span>in addition to
            the specific purposes for which we may process your personal data
            set out in this Section 2, we may also process any of your personal
            data where such processing is necessary for compliance with a legal
            obligation to which we are subject, or] in order to protect your
            vital interests or the vital interests of another natural person.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            m.<span class="Apple-converted-space">&nbsp; </span>If you wish to
            apply for a job on our website, we will collect Personally
            Identifiable Information such as your name, email address, phone
            number, insurance information, driver’s license, social security
            number and other information (“<b>employment date</b>”). We use the
            information collected within this area of the website to determine
            your qualifications for the position in which you have applied and
            to contact you to set up an interview.
          </span>
        </p>
        <p class="p8">
          <span class="s1">
            {" "}
            n.<span class="Apple-converted-space">&nbsp; </span>please do not
            supply any other person’s personal data to us unless we prompt you
            to do so and you have that person’s authorization to provide such.
          </span>
        </p>
        <p class="p9">
          <span class="s1">2.4 </span>
          <span class="s3">Non-Personally Identifiable Information.</span>
          <span class="s1">&nbsp;</span>
        </p>
        <p class="p9">
          <span class="s1">
            {" "}
            a. Non-Personally Identifiable Information is information that does
            not identify a specific person. This type of information may include
            things like the Uniform Resource Locator (“URL”) of the website you
            visited before coming to the Company Service or otherwise
            participating in the Company Service, the URL of the website you
            visit after leaving the Company Service, the type of browser you are
            using, your Internet Protocol (“IP”) address, mobile carrier
            information, mobile device information, or general and/or aggregated
            location data that does constitute Personally Identifiable
            Information. We, and/or our authorized Third-Party Service
            Providers, may automatically collect this information when you visit
            or use the Company Service using electronic tools like Cookies and
            Web beacons or Pixel tags, as described below in this Privacy
            Policy.<span class="Apple-converted-space">&nbsp; </span>We use
            Non-Personally Identifiable Information to troubleshoot, administer
            the Company Service, analyze trends, gather demographic information,
            comply with applicable law, and cooperate with law enforcement
            activities. We may also share this information with our authorized
            Third-Party Service Providers to measure the overall effectiveness
            of our products and services.
          </span>
        </p>
        <p class="p9">
          <span class="s1">
            {" "}
            b. Geolocation Data. We may use geolocation data which we track
            through mobile devices, which allows us to set your location, to
            track your progress and provide that information to you and your
            recipient, to send you promotions and offers, and to allow you (if
            you choose through any features we may provide) to share this
            information with other people. Except as otherwise permitted in this
            Privacy Policy, we will not share this information with third
            parties for any purpose and will only use this information for the
            sole purpose of providing you with the Services. You may at any time
            no longer allow our Services to use your location by turning this
            feature off at the mobile device level.
            <span class="Apple-converted-space">&nbsp; </span>We may associate
            your unique mobile device identifier with Service usage information
            and therefore with any Personally Identifiable Information you
            provide which may enable us to treat the combined information as
            Personally Identifiable Information.
            <span class="Apple-converted-space">&nbsp; </span>
          </span>
          <span class="s4">
            The legal basis for this processing is your consent or our
            legitimate interests, namely monitoring and improving our website
            and services.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            c. Behavioral Advertising and Opt-Out.
            <span class="Apple-converted-space">&nbsp; </span>Targeted
            advertising (also known as Behavioral Advertising) uses information
            collected on an individual’s web or mobile browsing behavior such as
            the pages they have visited or the searches they have made. This
            information is then used to select which advertisements should be
            displayed to a particular individual on websites other than our
            website. For example, if you have shown a preference for football
            while visiting our website, you may be served an advertisement for
            football-related equipment when you visit a site other than our
            website. The information collected is only linked to an anonymous
            cookie identifier; it does not include any information that could be
            linked back to a particular person, such as their name, address or
            credit card number. The information used for targeted advertising
            either comes from us or through third party website publishers.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            (i) We will not conduct targeted advertising using your Personal
            Information without your express consent as provided to us on our
            website.<span class="Apple-converted-space">&nbsp; </span>If at any
            time you would like to opt out of targeted advertising from us that
            occurs when visiting our third-party advertising publishers, please
            click here to access the NAI Opt-Out Page. Please note that this
            will opt you out of targeted ads from us and any other participating
            advertisers. If you opt out, you may continue to receive online
            advertising from us; however, these ads may not be as relevant to
            you.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            (ii) In order for behavioral advertising opt-outs to work on your
            device, your browser must be set to accept cookies. If you delete
            cookies, buy a new device, access our Services from a different
            device, login under a different screen name, or change web browsers,
            you will need to opt-out again. If your browser has scripting
            disabled, you do not need to opt out, as online behavioral
            advertising technology does not work when scripting is disabled.
            Please check your browser’s security settings to validate whether
            scripting is active or disabled.
          </span>
        </p>
        <p class="p3">
          <span class="s1">
            {" "}
            (ii) Additionally, many network advertising programs allow you to
            view and manage the interest categories they have compiled from your
            online browsing activities. These interest categories help determine
            the types of targeted advertisements you may receive. The NAI
            Opt-Out Page provides a tool that identifies its member companies
            that have cookies on your browser and provides links to those
            companies.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>3. </b>
          </span>
          <span class="s3">
            <b>PROVIDING YOUR PERSONAL DATA TO OTHERS</b>
          </span>
          <span class="s1">
            <b>.</b>
          </span>
        </p>
        <p class="p11">
          <span class="s1">
            3.1 We do not rent or sell your personally identifiable information
            to third parties.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.2 We may disclose your personal data to any member of our group of
            companies.<span class="Apple-converted-space">&nbsp; </span>This
            means our subsidiaries, our ultimate holding company and all its
            subsidiaries insofar as reasonably necessary for the purposes, and
            on the legal bases, set out in this policy.
            <span class="Apple-converted-space">&nbsp; </span>We may also
            disclose your personal data to the company to which we are providing
            Services, the terms and conditions under which you may have created
            an account for.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.3 Financial transactions relating to our website and services may
            be handled by our payment services providers. We will share
            transaction data with our payment services providers only to the
            extent necessary for the purposes of processing your payments,
            refunding such payments and dealing with complaints and queries
            relating to such payments and refunds.{" "}
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.4 We may disclose your inquiry data to one or more of those
            selected third-party suppliers of services via APIs identified on
            our website for the purpose of enabling them to connect your account
            with their Services, if any. Each such third party will act as a
            data controller in relation to the inquiry data that we supply to
            it.<span class="Apple-converted-space">&nbsp; </span>Each such third
            party will supply to you a copy of its own privacy policy, which
            will govern that third party’s use of your personal data.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.5 Company has an obligation when providing any and all of your
            data to any third party is to ensure that the third party
            effectively processes the personal data in a manner consistent with
            our own policies.<span class="Apple-converted-space">&nbsp; </span>
            In the event of a compliance failure by such third-party Company may
            be liable for the third-party lack of compliance and shall take
            appropriate steps to remedy the situation including, but not limited
            to, ensuring that processing by the third party shall cease and
            taking steps to remediate unauthorized processing.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.6 We may disclose your personal data where such disclosure is
            necessary for compliance with a legal obligation to which we are
            subject, or in order to protect your vital interests or the vital
            interests of another natural person. We may also disclose your
            personal data where such disclosure is necessary for the
            establishment, exercise or defense of legal claims, whether in court
            proceedings or in an administrative or out-of-court procedure.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            3.7 We disclose personal information in response to lawful requests
            by public authorities, including to meet national security or law
            enforcement requirements. We’ll notify you when we’re asked to hand
            over your personal information in this way unless we’re legally
            prohibited from doing so. When we receive requests like this, we’ll
            only release your personal information if we have a good faith
            belief that the law requires us to do so.
          </span>
        </p>
        <p class="p13">
          <span class="s1">
            3.8 If you provide your express consent, as part of your use of our
            Services, we may also publish your name, voice, likeness and other
            Personally Identifiable Information that is part of your account.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>4. </b>
          </span>
          <span class="s3">
            <b>ABOUT COOKIES.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            4.1 A cookie is a file containing an identifier (a string of letters
            and numbers) that is sent by a web server to a web browser and is
            stored by the browser. The identifier is then sent back to the
            server each time the browser requests a page from the server.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            4.2 Cookies may be either “persistent” cookies or “session” cookies:
            a persistent cookie will be stored by a web browser and will remain
            valid until its set expiry date, unless deleted by the user before
            the expiry date; a session cookie, on the other hand, will expire at
            the end of the user session.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            4.3 Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about you
            may be linked to the information stored in and obtained from
            cookies.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>5. </b>
          </span>
          <span class="s3">
            <b>COOKIES THAT WE USE.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">5.1 We use cookies for the following purposes:</span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (a) authentication – we use cookies to identify you when you visit
            our website and as you navigate our website.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (b) security – we use cookies as an element of the security measures
            used to protect user accounts, including preventing fraudulent use
            of login credentials, and to protect our website and services
            generally.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (c) analysis – we use cookies to help us to analyze the use and
            performance of our website and services; and
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>6. </b>
          </span>
          <span class="s3">
            <b>COOKIES USED BY OUR SERVICE PROVIDERS.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            6.1 Our service providers use cookies and those cookies may be
            stored on your computer when you visit our website.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            6.2 We use Google Analytics to analyze the use of our website.
            Google Analytics gathers information about website use by means of
            cookies. The information gathered relating to our website is used to
            create reports about the use of our website. Google’s privacy policy
            is available at:{" "}
            <a href="https://www.google.com/policies/privacy/">
              <span class="s5">https://www.google.com/policies/privacy/</span>
            </a>
            .
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>7. </b>
          </span>
          <span class="s3">
            <b>MANAGING COOKIES.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            7.1 Most browsers allow you to refuse to accept cookies and to
            delete cookies. The methods for doing so vary from browser to
            browser, and from version to version. You can however obtain
            up-to-date information about blocking and deleting cookies via these
            links:
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (a) https://support.google.com/chrome/answer/95647?hl=en (Chrome);
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (b)
            https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
            (Firefox);
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (c) http://www.opera.com/help/tutorials/security/cookies/ (Opera);
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (d)
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
            (Internet Explorer);
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (e) https://support.apple.com/kb/PH21411 (Safari); and
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            {" "}
            (f)
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
            (Edge).
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            7.2 Blocking all cookies will have a negative impact upon the
            usability of many websites.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            7.3 If you block cookies, you will not be able to use all the
            features on our website.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>8. </b>
          </span>
          <span class="s3">
            <b>WEB BEACONS</b> <b>&amp; EMBEDDED SCRIPTS</b>
          </span>
          <span class="s1">.</span>
        </p>
        <p class="p5">
          <span class="s1">
            8.1 Web beacons are small graphic images or computer code called web
            beacons may be included in our web and mobile pages and messages.
            The web beacons are tiny graphics with a unique identifier, similar
            in function to cookies, and are used to track the online movements
            of Web users. In contrast to cookies, which are stored in a user’s
            computer hard drive, web beacons are embedded invisibly on website
            pages. We may use web beacons or similar technologies to better
            manage content on our Services by informing us what content is
            effective, counting users of the Services, monitoring how users
            navigate the Services, counting how many e-mails that we send were
            actually opened or how many particular articles or links were
            actually viewed. We do not connect the information gathered by web
            beacons to our customers’ personal information.
            <span class="Apple-converted-space">&nbsp; </span>We may use web
            beacons for the legitimate purpose of enhancing the Services we
            provide to you.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            8.2 Embedded scripts are computer code that is designed to collect
            information about your interactions with the Services, such as the
            links you click on. The code is temporarily downloaded onto your
            device from our web server or a third-party service provider, is
            active only while you are connected to the Services and is
            deactivated or deleted thereafter.
            <span class="Apple-converted-space">&nbsp; </span>We may use
            embedded scripts for the legitimate purpose of enhancing the
            Services we provide to you.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>9. </b>
          </span>
          <span class="s3">
            <b>ANALYTICS.</b>
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            9.1 We continuously improve our websites and our products, and we
            utilize different third-party web analytics tools to help us do so.
            We are interested in how visitors use our websites, our desktop
            tools, our mobile applications, what they like and dislike, and
            where they have problems. Our products, desktop tools and mobile
            applications use analytical tools to understand feature usage
            patterns, to enhance and improve your product experience and offer
            you specific usage tips and guidance. In connection with normal
            business operations, as well as to increase functionality and
            enhance your user experience, this usage information may be linked
            to personal information that we have about individual users. We
            maintain ownership of this data and we do not share this type of
            data about individual users with third parties.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            9.2 Our mobile applications will also use analytical tools to help
            gather non-personally identifiable data about download and
            application usage. We may use information such as IP addresses to
            determine the general geographic locations areas of our visitors,
            but this information is not correlated to personal information about
            individual users. The web beacons used in conjunction with these
            tools may gather data such as what browser a person uses, what
            operating systems are used, domain names, MIME types, what is
            downloaded, and what content, products and services are reviewed
            when visiting or registering for services at one of our websites or
            using one of our mobile applications.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            9.3 This information is used solely to assist us in maintaining a
            more effective and useful websites and products for our customers.
            This data will not be shared with third parties without your prior
            consent or unless required by law.
          </span>
        </p>
        <p class="p14">
          <span class="s1">
            <b>10. </b>
          </span>
          <span class="s3">
            <b>SECURITY OF INFORMATION</b>
          </span>
          <span class="s1">. </span>
        </p>
        <p class="p14">
          <span class="s1">
            10.1 You may be able to access your Personally Identifiable
            Information via the Company Service with your password and username.
            This password is encrypted. We advise against sharing your password
            with anyone.<span class="Apple-converted-space">&nbsp; </span>If you
            access your account via a third-party site or service, you may have
            additional or different sign-in protections via that third-party
            site or service. You need to prevent unauthorized access to your
            account and Personal Information by selecting and protecting your
            password and/or other sign-in mechanism appropriately and limiting
            access to your computer, browser, or mobile device by signing off
            after you have finished accessing your account. Unauthorized entry
            or use, hardware or software failure, and other factors, may
            compromise the security of user information at any time. If we
            believe that the security of your information may have been
            compromised, we may seek to notify you of that development.{" "}
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            10.2 We are committed to taking all reasonable precautions to
            safeguard the privacy of personal information which has been
            provided by its users. We use technical, contractual, administrative
            and physical security steps to protect your personal information.
            Security measures such as restricted access and the use of passwords
            and encryption have been adopted to protect your personal
            information against loss or theft, as well as unauthorized access,
            disclosure, copying, use or modification. Our employees have been
            trained to respect your privacy at all times and those employees
            with access to your personal information shall use your personal
            information strictly in accordance with this Privacy Policy. We will
            protect your credit card information by using industry standard
            encryption technology.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            10.3 Please be aware that no data transmission over the Internet or
            via e-mail is completely secure and therefore we cannot guarantee
            protection of all personal information in all cases. For example, we
            cannot guarantee protection against interception, misappropriation,
            misuse, or alteration, or that your information will not be
            disclosed or accessed by the unauthorized acts of others.
            Consequently, we cannot ensure or warrant the security of any
            information you transmit to us, and you do so at your own risk. If
            you provide us with your credit card number, you should not send it
            electronically unless the email is encrypted, or your browser
            indicates that the access to our website is secure. Materials posted
            to online forums such as bulletin boards or chat rooms are public,
            not secure, and may be viewed by anyone. Any personal information
            you post may be collected and used by anyone and may result in
            unsolicited messages from other parties.
          </span>
        </p>
        <p class="p14">
          <span class="s1">
            <b>11. Public Areas</b>. We may provide areas on the Company Service
            where you can publicly post information about yourself or can
            communicate with others such as on forums. This information may be
            accessible by other users and companies and may appear on other
            websites or web searches, and therefore this information could be
            read, collected, and used by others. We have no control over who
            reads your postings or what other users may do with the information
            you voluntarily post, so please use caution when posting any content
            or providing anything that could be deemed personal information.
          </span>
        </p>
        {/* <p class="p15">
          <span class="s1">
            <b>
              12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </b>
          </span>
          <span class="s3">
            <b>DATA STORAGE IN THE EU</b>
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            Information we collect, including, Personally identifiable
            Information, from Customers located in the European Union will be
            processed and stored in the European Union. Your Personally
            Identifiable information may be subject to access requests from
            governments, courts, or law enforcement in the EU according to laws
            of the EU or jurisdictions within the EU. By using the Services or
            providing us with any information, you consent to the processing and
            storage of your information in the EU.
          </span>
        </p> */}
        <p class="p1">
          <span class="s1">
            <b>12. </b>
          </span>
          <span class="s3">
            <b>YOUR RIGHTS</b>
          </span>
          <span class="s1">
            <b>.</b>
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.1 In this Section 12 we have summarized the rights that you may
            have under data protection law in accordance with your location or
            jurisdiction of residence. Some of the rights are complex, and not
            all of the details have been included in our summaries. Accordingly,
            you should read the relevant laws and guidance from the regulatory
            authorities for a full explanation of these rights.
            <span class="Apple-converted-space">&nbsp; </span>Generally
            speaking, the following rights are most applicable to Users in the
            European Union and less so to Users in the United States.
            <span class="Apple-converted-space">&nbsp; </span>More information
            may be available in our Terms of Service as it relates to this
            topic.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.2 Your principal rights under data protection law are:
          </span>
        </p>
        <p class="p1">
          <span class="s1"> a. the right to access;</span>
        </p>
        <p class="p1">
          <span class="s1"> b. the right to rectification;</span>
        </p>
        <p class="p1">
          <span class="s1"> c. the right to erasure;</span>
        </p>
        <p class="p1">
          <span class="s1"> d. the right to restrict processing;</span>
        </p>
        <p class="p1">
          <span class="s1"> e. the right to object to processing;</span>
        </p>
        <p class="p1">
          <span class="s1"> f. the right to data portability;</span>
        </p>
        <p class="p1">
          <span class="s1">
            {" "}
            g. the right to complain to a supervisory authority; and
          </span>
        </p>
        <p class="p1">
          <span class="s1"> h. the right to withdraw consent.</span>
        </p>
        <p class="p1">
          <span class="s1">
            12.3 You have the right to confirmation as to whether or not we
            process your personal data and, where we do, access to the personal
            data, together with certain additional information. That additional
            information includes details of the purposes of the processing, the
            categories of personal data concerned and the recipients of the
            personal data. Providing the rights and freedoms of others are not
            affected, we will supply to you a copy of your personal data. The
            first copy will be provided free of charge, but additional copies
            may be subject to a reasonable fee.{" "}
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.4 You have the right to have any inaccurate personal data about
            you rectified and, considering the purposes of the processing, to
            have any incomplete personal data about you completed.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.5 In some circumstances you have the right to the erasure of your
            personal data without undue delay. Those circumstances include: the
            personal data are no longer necessary in relation to the purposes
            for which they were collected or otherwise processed; you withdraw
            consent to consent-based processing; you object to the processing
            under certain rules of applicable data protection law; the
            processing is for direct marketing purposes; and the personal data
            have been unlawfully process. However, there are exclusions of the
            right to erasure. The general exclusions include where processing is
            necessary: for exercising the right of freedom of expression and
            information; for compliance with a legal obligation; or for the
            establishment, exercise or defense of legal claims.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.6 In some circumstances you have the right to restrict the
            processing of your personal data. Those circumstances are: you
            contest the accuracy of the personal data; processing is unlawful
            but you oppose erasure; we no longer need the personal data for the
            purposes of our processing, but you require personal data for the
            establishment, exercise or defense of legal claims; and you have
            objected to processing, pending the verification of that objection.
            Where processing has been restricted on this basis, we may continue
            to store your personal data. However, we will only otherwise process
            it: with your consent; for the establishment, exercise or defense of
            legal claims; for the protection of the rights of another natural or
            legal person; or for reasons of important public interest.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.7 You have the right to object to our processing of your personal
            data on grounds relating to your particular situation, but only to
            the extent that the legal basis for the processing is that the
            processing is necessary for: the performance of a task carried out
            in the public interest or in the exercise of any official authority
            vested in us; or the purposes of the legitimate interests pursued by
            us or by a third party. If you make such an objection, we will cease
            to process the personal information unless we can demonstrate
            compelling legitimate grounds for the processing which override your
            interests, rights and freedoms, or the processing is for the
            establishment, exercise or defense of legal claims.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.8 You have the right to object to our processing of your personal
            data for direct marketing purposes (including profiling for direct
            marketing purposes). If you make such an objection, we will cease to
            process your personal data for this purpose.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.9 You have the right to object to our processing of your personal
            data for scientific or historical research purposes or statistical
            purposes on grounds relating to your particular situation, unless
            the processing is necessary for the performance of a task carried
            out for reasons of public interest.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.10 To the extent that the legal basis for our processing of your
            personal data is:
          </span>
        </p>
        <p class="p1">
          <span class="s1"> a. consent; or</span>
        </p>
        <p class="p1">
          <span class="s1">
            {" "}
            b. that the processing is necessary for the performance of a
            contract to which you are party or in order to take steps at your
            request prior to entering into a contract, and such processing is
            carried out by automated means, you have the right to receive your
            personal data from us in a structured, commonly used and
            machine-readable format. However, this right does not apply where it
            would adversely affect the rights and freedoms of others.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.11 If you consider that our processing of your personal
            information infringes data protection laws, you have a legal right
            to lodge a complaint with a supervisory authority responsible for
            data protection.{" "}
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.12 Under certain limited conditions you may have the right to
            invoke binding arbitration before the Privacy Shield Panel as
            created by the U.S. Department of Commerce and the European
            Commission.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            12.13 To the extent that the legal basis for our processing of your
            personal information is consent, you have the right to withdraw that
            consent at any time. Withdrawal will not affect the lawfulness of{" "}
          </span>
        </p>
        <p class="p1">
          <span class="s1">processing before the withdrawal.</span>
        </p>
        <p class="p1">
          <span class="s1">
            <b>
              13.
              <span class="Apple-converted-space">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </span>
              RIGHTS, CALIFORNIA PRIVACY RIGHTS AND NOTICE TO CANADIAN
              RESIDENTS.
            </b>
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            13.1 California’s “Shine the Light” law, California Civil Code §
            1798.83, requires certain businesses to respond to requests from
            California customers (those who have an established business
            relationship with us) asking about the business’ practices related
            to disclosing Personal Information to third parties for the third
            parties’ direct marketing purposes. Alternately, such businesses may
            have in place a policy not to disclose Personal Information of
            customers to third parties for the third parties’ direct marketing
            purposes unless the customer first affirmatively agrees to the
            disclosure (opt-in) or if the customer has exercised an option to
            opt-out of such information-sharing (opt-out).
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            13.2 We have opted for this alternative approach, and we do not
            share Personal Information of customers information to third parties
            for the third parties’ direct marketing purposes without your
            permission.&nbsp;
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            13.3 Company collects various types of Personal Information about
            you online, as described in this Privacy Policy. Under California
            and Canadian law, Share Bibles customers who are residents of
            California or Canada may request certain information about our
            disclosure of personal information during the prior calendar year to
            third parties for their direct marketing purposes. To make such a
            request, please write to us at the address below.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            <b>14. </b>
          </span>
          <span class="s3">
            <b>RETAINING AND DELETING PERSONALLY IDENTIFIABLE INFORMATION.</b>
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            14.1 This Section 15 sets out our data retention policies and
            procedure, which are designed to help ensure that we comply with our
            legal obligations in relation to the retention and deletion of
            personal data.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            14.2 Personal data that we process for any purpose or purposes shall
            be kept until such time as we receive a request for deletion from
            you.
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            14.3 Notwithstanding the other provisions of this Section 10, we may
            retain your personal data where such retention is necessary for
            compliance with a legal obligation to which we are subject.
          </span>
        </p>
        <p class="p5">
          <span class="s1">
            <b>
              15.{" "}
              <span class="Apple-converted-space">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </span>
            </b>
          </span>
          <span class="s3">
            <b>AGE.</b>
          </span>
          <span class="s1">
            <span class="Apple-converted-space">&nbsp; </span>The Service is
            meant for those at least eighteen (18) years of age.
            <span class="Apple-converted-space">&nbsp; </span>Use of the Service
            by anyone under this age is a violation of the Terms of Service.
            <span class="Apple-converted-space">&nbsp; </span>We do not
            knowingly collect Personal Information from anyone under the age of
            18. If we discover that we have collected Personal Information from
            a person under 18, we will delete it as soon as reasonably possible.
            If you are a parent or guardian of a minor under the age of eighteen
            (18) and believe he or she has disclosed Personal Information to us,
            please contact us at{" "}
            <a href="mailto:info@sharebibles.com">
              <span class="s5">info@sharebibles.com</span>
            </a>
            .
          </span>
        </p>
        <p class="p1">
          <span class="s1">
            <b>
              16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </b>
          </span>
          <span class="s3">
            <b>CONTACT US</b>
          </span>
          <span class="s1">
            <b>
              .<span class="Apple-converted-space">&nbsp; </span>
            </b>
            If you have any questions or requests regarding this Privacy Policy
            or your Personal Information or Usage Information, please contact
            our Data Protection Officer: info@sharebibles.com
          </span>
        </p>
      </div>
    </Layout>
  );
};
